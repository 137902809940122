import {
  type GetLocationPathFromIdQuery,
  type UserLocation,
  useGetLocationPathFromIdLazyQuery,
} from '@kijiji/generated/graphql-types'
import { useDecision } from '@optimizely/react-sdk'
import { useSession } from 'next-auth/react'
import { useEffect, useMemo, useRef } from 'react'

import { ALL_CATEGORIES_ID_NUM } from '@/constants/category'
import { ACCEPTED_L1_LOCATIONS, CHAMELEON_FAST_URL } from '@/constants/chameleon'
import {
  ALL_CANADA_LABEL_EN,
  ALL_CANADA_LOCATION_ID,
  DEFAULT_USER_LOCATION,
} from '@/constants/location'
import { isUserAuthenticated } from '@/domain/user'
import { FEATURE_FLAG } from '@/lib/optimizely'
import { getUserDevice } from '@/utils/getUserDevice'
import { sendToLogger } from '@/utils/sendToLogger'

export type UseChameleonProps = {
  categoryId?: number
  location?: UserLocation
  userAgent?: string
}

export type LocationPathsType = NonNullable<
  NonNullable<GetLocationPathFromIdQuery['location']>['locationPaths']
>

/**
 * Custom hook for integrating with Chameleon tracking.
 *
 * @param options - The options for configuring Chameleon tracking.
 * @param options.categoryId - The category ID for tracking. Defaults to ALL_CATEGORIES_ID_NUM.
 * @param options.location - The user's location for tracking. Defaults to DEFAULT_USER_LOCATION.
 * @param options.userAgent - The user agent string for tracking. Defaults to an empty string.
 */
export const useChameleon = ({
  categoryId = ALL_CATEGORIES_ID_NUM,
  location = DEFAULT_USER_LOCATION,
  userAgent = '',
}: UseChameleonProps) => {
  // Create a ref so that we only run the useEffect once
  const hasSentDataToChameleon = useRef(false)
  const [chameleonToggle] = useDecision(FEATURE_FLAG.CHAMELEON_TRACKING)
  const { data: userData, status: userStatus } = useSession()
  const [getLocationPath] = useGetLocationPathFromIdLazyQuery()
  const { isPhone: isMobilePhone, isAppleDevice, isAndroidDevice } = getUserDevice(userAgent)

  // Memoize the result of the mobile operating system detection
  const getMobileOperatingSystem = useMemo(() => {
    if (isAppleDevice) {
      return 'iOS'
    } else if (isAndroidDevice) {
      return 'Android'
    } else {
      return ''
    }
  }, [isAppleDevice, isAndroidDevice])

  // Function to check if the user is in a supported location
  const isUserInSupportedLocation = (locationList: LocationPathsType) => {
    return locationList.length >= 2 && ACCEPTED_L1_LOCATIONS.includes(locationList[1].id)
  }

  // Function to initialize Chameleon
  const initializeChameleon = async () => {
    try {
      const chmln = await import('@chamaeleonidae/chmln')

      await chmln.init(process.env.NEXT_PUBLIC_CHAMELEON_TOKEN, {
        fastUrl: CHAMELEON_FAST_URL,
      })

      return chmln
    } catch (error) {
      sendToLogger(error, { fingerprint: ['Chameleon'] })
    }
  }

  const sendDataToChameleon = async () => {
    // chameleon is ON and the user is LOGGED IN
    if (chameleonToggle.enabled && isUserAuthenticated(userStatus)) {
      // check their location
      const { data: locationData } = await getLocationPath({
        variables: {
          locationId: location.id || ALL_CANADA_LOCATION_ID,
        },
      })
      const locationList = locationData?.location?.locationPaths || []

      if (isUserInSupportedLocation(locationList)) {
        try {
          const chmln = await initializeChameleon()

          // identify the user by sending a ID and info
          await chmln.identify(userData?.user.sub, {
            category_id: categoryId,
            device_type: isMobilePhone ? 'Mobile' : 'Desktop',
            email: userData?.user.email,
            l1_location: locationList[1]?.id,
            l2_location: locationList[2]?.id,
            l3_location: locationList[3]?.id,
            location_name: location?.name?.en_CA || ALL_CANADA_LABEL_EN,
            logged_in_status: true,
            mobile_os: getMobileOperatingSystem,
          })
        } catch (error) {
          sendToLogger(error, { fingerprint: ['Chameleon'] })
        }
      }
    }
  }

  useEffect(() => {
    if (!hasSentDataToChameleon.current) {
      hasSentDataToChameleon.current = true
      sendDataToChameleon()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStatus, location])
}
