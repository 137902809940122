import { InlineAdSlotWrapper, StyledInlineAdSlot } from '@/lib/ads/ad-slots/srp/styled'
import { GPT_COMPONENT_ID, INLINE_AD_POSITIONS } from '@/lib/ads/constants/adSlots'

export type InlineAdSlotProps = {
  index: number
  totalListings: number
}

export const InlineAdSlot = ({ index, totalListings }: InlineAdSlotProps) => {
  // inline ad slots occupy ad positions 3-41, see Miro
  // https://miro.com/app/board/uXjVOhxPqW0=/?share_link_id=383582596557
  const adPositionNumber = index + 3

  // need the index for creating the div id
  const indexOfPositionSetting = INLINE_AD_POSITIONS.findIndex(
    (position) => position === adPositionNumber
  )

  // findIndex returns -1 if no match is found
  if (indexOfPositionSetting === -1) return null

  // GOT ids for inline ads are numbered 1 to 4
  const inlineAdId = `${GPT_COMPONENT_ID.INLINE}_${indexOfPositionSetting + 1}`

  // inline ads should never be after the final listing
  const isNotLastListing = index < totalListings - 1

  // AdSlots must be a div; wrapped in an li for a11y.
  return isNotLastListing ? (
    <InlineAdSlotWrapper>
      <StyledInlineAdSlot key={inlineAdId} id={inlineAdId} data-testid={inlineAdId} />
    </InlineAdSlotWrapper>
  ) : null
}
