import { isAnyVacationRentalsCategory } from '@kijiji/category'
import {
  type AdditionalFlags,
  type AppliedAttributeFilter,
  type AppliedDateFilter,
  type AppliedDateRangeFilter,
  type AppliedFilter,
  type AppliedRangeFilter,
  type AppliedToggleFilter,
  type AttributeFilter,
} from '@kijiji/generated/graphql-types'
import { spacing } from '@kijiji/theme'
import { type FC, Fragment, useCallback, useRef } from 'react'

import { CategoryTreeFilter } from '@/components/srp/filters/filter-types/CategoryTreeFilter'
import { LocationTreeFilter } from '@/components/srp/filters/filter-types/LocationTreeFilter'
import { FilterGroup as FilterGroupComponent } from '@/components/srp/filters/FiltersAccordion/FilterGroup'
import { FiltersDivider } from '@/components/srp/filters/styled'
import { hasValuesProperty } from '@/components/srp/filters/utils'
import { ALL_CATEGORIES_ID_NUM } from '@/constants/category'
import { ALL_CANADA_LOCATION_ID } from '@/constants/location'
import { seperateTreesAndCleanFilters } from '@/domain/filters'
import { getExpandedFilterIdList } from '@/domain/srp/filters/getExpandedFilterIdList'
import { type ParentFilter } from '@/domain/srp/filters/getParentFilter'
import { useGetSearchResultsData } from '@/hooks/srp/useGetSearchResultsData'
import {
  type RefetchInput,
  type RefetchResultsType,
  FilterKeysEnum,
  useSearchActions,
} from '@/hooks/srp/useSearchActions'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { isToggleFilter } from '@/types/search'
import { Accordion, AccordionItem } from '@/ui/atoms/accordion'
import { Flex } from '@/ui/atoms/flex'
import { type NullableFields } from '@/utils/types'

export type FilterProps<T, V> = {
  filter: T
  refetch: (args: AppliedFilter & NullableFields<V>, trackingLabel?: string) => void
  parentFilter?: ParentFilter
  isMobile?: boolean
  isSrpLoading?: boolean
}

export type TreeFilterProps = {
  filter: AttributeFilter
  selectedId?: number
  refetch: RefetchResultsType
}

export type FilterValuesUnion =
  | NullableFields<Omit<AppliedAttributeFilter, 'filterName'>>
  | NullableFields<Omit<AppliedDateFilter, 'filterName'>>
  | NullableFields<Omit<AppliedDateRangeFilter, 'filterName'>>
  | NullableFields<Omit<AppliedRangeFilter, 'filterName'>>
  | NullableFields<Omit<AppliedToggleFilter, 'filterName'>>

export type FilterRefetchResults<A> = (
  type: FilterKeysEnum,
  filterName: string,
  value: A,
  trackingLabel?: string
) => void

type AppliedAdditionalFlags = {
  [key: string]: AdditionalFlags[]
}

export const FiltersAccordion: FC<{ isMobile?: boolean }> = ({ isMobile }) => {
  const { data } = useGetSearchResultsData()
  const { controls, searchQuery } = data || {}
  const { refetchResults } = useSearchActions()
  const appliedAdditionalFlags = useRef<AppliedAdditionalFlags>({})

  const filters = controls?.filtering || []
  const selectedCategoryId = searchQuery?.category?.id || ALL_CATEGORIES_ID_NUM
  const selectedLocationId = searchQuery?.location?.id || ALL_CANADA_LOCATION_ID

  const preExpandedFilters = isMobile ? [] : getExpandedFilterIdList(selectedCategoryId)

  const refetchResultsHandler: FilterRefetchResults<FilterValuesUnion> = useCallback(
    (type, filterName, value, trackingLabel) => {
      let refetchInput: Partial<RefetchInput> = {}

      if (type === FilterKeysEnum.ADDITIONAL_FLAG_FILTERS) {
        if (!hasValuesProperty(value)) return

        const newAppliedAdditionalFlags: AppliedAdditionalFlags = {
          ...appliedAdditionalFlags.current,
          [filterName]: (value.values as AdditionalFlags[]) || [],
        }
        appliedAdditionalFlags.current = newAppliedAdditionalFlags

        refetchInput = {
          [FilterKeysEnum.ADDITIONAL_FLAG_FILTERS]: Object.values(newAppliedAdditionalFlags).flat(),
        }
      } else {
        refetchInput = {
          [type]: [{ filterName, ...value }],
        }
      }

      refetchResults(refetchInput, {
        debounce: true,
        event: trackingLabel ? { action: GA_EVENT.FilterSelect, label: trackingLabel } : undefined,
      })
    },
    [refetchResults]
  )

  const { categoryTree, locationTree, filterGroups } = seperateTreesAndCleanFilters(
    filters,
    selectedCategoryId
  )

  return (
    <Accordion gap={0} preExpanded={preExpandedFilters}>
      {categoryTree && (
        <CategoryTreeFilter
          filter={categoryTree}
          selectedId={selectedCategoryId}
          refetch={refetchResults}
        />
      )}

      <FiltersDivider />

      {locationTree && !isAnyVacationRentalsCategory(selectedCategoryId) && (
        <LocationTreeFilter
          filter={locationTree}
          selectedId={selectedLocationId}
          refetch={refetchResults}
        />
      )}

      {filterGroups.map((item, index) => {
        if (item.shouldShowTopLevelAccordion) {
          const selectedFilters = item.filters.filter(
            (filter) => isToggleFilter(filter) && filter.isSelected
          )

          let filterDescription = ''
          if (selectedFilters.length === 1) {
            filterDescription = selectedFilters[0].label
          } else if (selectedFilters.length > 1) {
            filterDescription = `${selectedFilters[0].label} + ${selectedFilters.length - 1}`
          }

          return (
            <Fragment key={item.name}>
              <FiltersDivider />
              <AccordionItem
                id={item.name}
                title={item.label ?? ''}
                description={filterDescription}
              >
                <Flex flexDirection="column" gap={spacing.default} data-testid="toggle-filter">
                  <FilterGroupComponent filterGroup={item} refetch={refetchResultsHandler} />
                </Flex>
              </AccordionItem>
            </Fragment>
          )
        }

        return (
          <FilterGroupComponent filterGroup={item} key={index} refetch={refetchResultsHandler} />
        )
      })}
    </Accordion>
  )
}
