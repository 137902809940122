import { useTranslation } from 'next-i18next'
import { type FC, useState } from 'react'
import { useTheme } from 'styled-components'

import { ShowAtOrLarger, ShowWhenSmaller } from '@/components/shared/breakpoint'
import { ClientRender } from '@/components/shared/client-render'
import { ImageYAMS } from '@/components/shared/image/ImageYAMS'
import FavouriteButton from '@/components/shared/listing/FavouriteButton'
import { AutosAttributeList } from '@/components/srp/listing-card/autos-attribute-list'
import { AutosBadges } from '@/components/srp/listing-card/autos-badges'
import { GetMediaFavouriteButtonSize } from '@/components/srp/listing-card/get-media-favourite-button-size'
import { ListingDetails } from '@/components/srp/listing-card/listing-details'
import { ListingDetailsDividerStyle } from '@/components/srp/listing-card/listing-details/ListingDetails'
import { ListingImageBadge } from '@/components/srp/listing-card/listing-image-badge'
import { ListingPrice } from '@/components/srp/listing-card/listing-price'
import { ListingPriceVariant } from '@/components/srp/listing-card/listing-price/ListingPrice'
import { type ListingCardProps } from '@/components/srp/listing-card/ListingCard'
import {
  AutosCardDescriptionContainer,
  AutosListingCardBottomData,
  AutosListingCardContainer,
  AutosListingCardDivider,
  AutosListingCardHeading,
  AutosListingCardLogoContainer,
  AutosListingCardTopData,
  AutosListingImageBase,
  ExpandDescriptionButton,
  ListingCardBody,
  ListingCardDetails,
  ListingCardLink,
  ListingRightSide,
} from '@/components/srp/listing-card/styled'
import { highlightKeywords } from '@/components/srp/search-list/highlightKeywords'
import { Showcase } from '@/components/srp/showcase'
import { autosListingMainImageUrlRuleSize, autosShowcaseThumbnailSizes } from '@/constants/listings'
import { truncateText } from '@/domain/srp/truncateText'
import { BadgeSize } from '@/ui/atoms/badge/Badge'
import { BodyText } from '@/ui/atoms/body-text'
import { RotatingChevron } from '@/ui/atoms/rotating-chevron'
import { Spacing } from '@/ui/atoms/spacing'

export type AutosListingAttributes = {
  /**
   * List of dealer updates
   */
  dealerUpdates?: string[]
  /**
   * Defines if car listing has Carfax report
   */
  hasCarfaxReport?: boolean
  /**
   * Listing position
   */
  index?: number
  /**
   * Defines if car listing is certified pre-owned
   */
  isCertifiedPreOwned?: boolean
  /**
   * Retail price of listing
   * It will only have a value if the car is new
   */
  msrp?: number
  /**
   * Number of km
   */
  km?: string
  /**
   * Transmission type
   */
  transmission?: string
  /**
   * Fuel type
   */
  fuelType?: string
}

type OmittedPropsFromGenericListingCard =
  | 'attributes'
  | 'autos'
  | 'realEstate'
  | 'realEstateAttributes'
  | 'location'

export type AutosListingCardProps = Omit<ListingCardProps, OmittedPropsFromGenericListingCard> & {
  /**
   * Attributes specific for autos categories
   */
  attributes: AutosListingAttributes
}

/**
 * `AutosListingCard` is a component that renders a listing card for autos categories
 *
 * @component
 */
export const AutosListingCard: FC<AutosListingCardProps> = ({
  activationDate,
  adSource,
  attributes,
  description: possiblyNullDescription = '',
  handleTrackListingClick,
  id,
  imageBadge,
  isHighlight,
  locationName,
  logoSrc,
  mainImage,
  price,
  proximity,
  sortingDate,
  showcaseImages,
  title,
  url,
  isInTopAdsSection,
  isMobile,
  index,
  globalKeyword,
}) => {
  const { colors, spacing } = useTheme()
  const { t } = useTranslation('srp')

  const shouldShowAttributes = Object.keys(attributes)?.length > 0
  const { dealerUpdates = [], hasCarfaxReport, isCertifiedPreOwned, msrp } = attributes
  const [showDescription, setShowDescription] = useState<boolean>(false)

  const hasContactlessServices = !!dealerUpdates.length
  const description = possiblyNullDescription || ''

  const shouldRenderBottomData =
    logoSrc || description || hasCarfaxReport || isCertifiedPreOwned || hasContactlessServices

  const shouldRenderBadges = hasCarfaxReport || isCertifiedPreOwned || hasContactlessServices

  const renderShowcase = () => {
    if (!showcaseImages?.length) return null

    return (
      <Showcase
        thumbnailSizes={autosShowcaseThumbnailSizes}
        itemBorderRadius="small"
        itemListGap="defaultSmall"
        maxNumberOfPictures={9}
        seoUrl={url}
        showcaseImages={showcaseImages}
        handleListingCardClick={handleTrackListingClick}
        showDivider={false}
        baseAltText={truncateText(description, 100)}
      />
    )
  }

  const getListingImageSize = () => {
    return isHighlight ? 'xxLarge' : 'xLarge'
  }

  const expandDescription = () => {
    setShowDescription(!showDescription)
  }

  return (
    <AutosListingCardContainer data-testid="listing-card" data-listingid={id}>
      <AutosListingCardTopData isHighlight={isHighlight}>
        <AutosListingImageBase
          badge={imageBadge ? <ListingImageBadge imageBadge={imageBadge} /> : null}
          data-testid={`listing-card-image-container${isHighlight ? '-highlight' : ''}`}
          imageComponent={
            <ImageYAMS
              alt={truncateText(description, 100) || title}
              data-testid="listing-card-image"
              src={mainImage}
              width={autosListingMainImageUrlRuleSize}
              height={300}
              extension="WEBP"
              priority={isInTopAdsSection && index === 0}
              loading={index < 2 ? 'eager' : 'lazy'}
            />
          }
          size={getListingImageSize()}
        />
        <ListingCardDetails>
          <ListingCardBody>
            <ListingPrice
              displayInlinePrice={isHighlight}
              msrp={msrp}
              priceAmount={price.amount}
              priceOriginal={price.original}
              type={price.type}
              surcharges={price.surcharges}
              variant={ListingPriceVariant.Autos}
            />
            <AutosListingCardHeading
              data-testid="listing-title"
              forwardedAs="h3"
              color={colors.grey.primary}
              size="small"
              weight={isMobile ? 'medium' : 'regular'}
            >
              <ListingCardLink
                data-testid="listing-link"
                href={url}
                onClick={handleTrackListingClick}
                variant="grey"
              >
                {highlightKeywords(id, title, globalKeyword)}
              </ListingCardLink>
            </AutosListingCardHeading>

            {shouldShowAttributes ? <AutosAttributeList attributes={attributes} /> : null}

            <Spacing mBottom={spacing.defaultSmall}>
              <ListingDetails
                activationDate={activationDate}
                sortingDate={isInTopAdsSection ? undefined : sortingDate}
                locationName={locationName}
                proximity={proximity}
                listingAdSource={adSource}
                dividerStyle={ListingDetailsDividerStyle.DOT}
                isMobileFormat={isMobile}
              />
            </Spacing>
            <ShowAtOrLarger breakpoint="medium">{renderShowcase()}</ShowAtOrLarger>
          </ListingCardBody>

          <ListingRightSide>
            <ClientRender>
              <GetMediaFavouriteButtonSize>
                {({ size }) => <FavouriteButton colorScheme="purple" listingId={id} size={size} />}
              </GetMediaFavouriteButtonSize>
            </ClientRender>
          </ListingRightSide>
        </ListingCardDetails>
      </AutosListingCardTopData>
      <ShowWhenSmaller breakpoint="medium">{renderShowcase()}</ShowWhenSmaller>
      {shouldRenderBottomData && (
        <>
          <AutosListingCardDivider
            top={spacing.defaultSmall}
            bottom={spacing.defaultSmall}
            showDivider={!!(logoSrc || shouldRenderBadges)}
          />

          <AutosListingCardBottomData
            flexWrap="wrap"
            gap={logoSrc ? spacing.default : '0'}
            alignItems="center"
          >
            {logoSrc && (
              <AutosListingCardLogoContainer>
                <ImageYAMS data-testid="logo-container" fill src={logoSrc} alt="" />
              </AutosListingCardLogoContainer>
            )}
            {shouldRenderBadges && (
              <AutosBadges horizontalOverflow hasDescription={!!description}>
                {isCertifiedPreOwned && (
                  <AutosBadges.CertifiedPreOwned size={BadgeSize.LARGE} variant="badge" />
                )}
                {hasCarfaxReport && <AutosBadges.CarFax size={BadgeSize.LARGE} variant="badge" />}
                {hasContactlessServices && (
                  <AutosBadges.Contactless size={BadgeSize.LARGE} variant="badge" />
                )}
              </AutosBadges>
            )}
            {description && (
              <ExpandDescriptionButton
                onClick={expandDescription}
                data-testid="listing-description-button"
                aria-label={t('listing.description_button.label')}
                aria-expanded={showDescription}
                aria-controls={`expandable-description-${id}`}
              >
                <RotatingChevron isOpen={showDescription} />
              </ExpandDescriptionButton>
            )}
          </AutosListingCardBottomData>
          <AutosCardDescriptionContainer
            showDescription={showDescription}
            id={`expandable-description-${id}`}
          >
            <BodyText color={colors.grey.primary} data-testid="listing-description" size="small">
              {highlightKeywords(id, description, globalKeyword)}
            </BodyText>
          </AutosCardDescriptionContainer>
        </>
      )}
    </AutosListingCardContainer>
  )
}
