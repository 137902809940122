import { type AppliedDateRangeFilter } from '@kijiji/generated/graphql-types'
import { useTranslation } from 'next-i18next'
import { type ChangeEvent, type FC, useState } from 'react'
import { useTheme } from 'styled-components'

import { ApplyFilter } from '@/components/srp/filters/filter-types/ApplyFilter'
import { SelectedValue } from '@/components/srp/filters/filter-types/SelectedValue'
import { type FilterProps } from '@/components/srp/filters/FiltersAccordion/FiltersAccordion'
import { useFiltersLoading } from '@/hooks/filters/useFiltersLoading'
import { type DateRangeFilter as DateRangeFilterType } from '@/types/search'
import { AccordionItem } from '@/ui/atoms/accordion'
import { BodyText } from '@/ui/atoms/body-text'
import { Spacing } from '@/ui/atoms/spacing'
import { DateField } from '@/ui/molecules/date-field'

export const DateRangeFilter: FC<FilterProps<DateRangeFilterType, AppliedDateRangeFilter>> = ({
  filter,
  parentFilter,
  isSrpLoading = false,
  refetch,
}) => {
  const [loading, setLoading] = useFiltersLoading(isSrpLoading)

  const { name, label, selectedRangeValues } = filter

  const { t } = useTranslation('srp')
  const { spacing, colors } = useTheme()

  const getInitialStartDate = () => {
    if (selectedRangeValues && 'start' in selectedRangeValues) {
      return selectedRangeValues.start || ''
    }
    return ''
  }

  const getInitialEndDate = () => {
    if (selectedRangeValues && 'end' in selectedRangeValues) {
      return selectedRangeValues.end || ''
    }
    return ''
  }

  const [startDate, setStartDate] = useState<string>(getInitialStartDate())
  const [endDate, setEndDate] = useState<string>(getInitialEndDate())

  const handleStartDateChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const startDate = evt.target.value
    setStartDate(startDate)
  }

  const handleEndDateChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const endDate = evt.target.value
    setEndDate(endDate)
  }

  const handleResetRange = () => {
    setStartDate('')
    setEndDate('')
    setLoading(true)

    const trackingLabel = `${name} = [undefined, undefined]`
    refetch({ filterName: name, start: null, end: null }, trackingLabel)
  }

  const handleApplyFilter = () => {
    const normalizedStartDate = startDate || null
    const normalizedEndDate = endDate || null
    setLoading(true)

    const trackingLabel = `${name} = [${normalizedStartDate || undefined}, ${
      normalizedEndDate || undefined
    }]`

    refetch(
      {
        filterName: name,
        start: normalizedStartDate,
        end: normalizedEndDate,
      },
      trackingLabel
    )
  }

  const parentFilterLabel =
    parentFilter &&
    !parentFilter.isSelected &&
    t('filters.select_parent_filter', { parentFilter: parentFilter?.label })

  const filterDescription =
    parentFilterLabel || (!!selectedRangeValues && <SelectedValue filter={filter} />)

  return (
    <AccordionItem
      id={name}
      key={`${name}-accordion-item`}
      title={label || ''}
      data-testid="date-range-filter"
      description={filterDescription}
      isDisabled={parentFilter && !parentFilter.isSelected}
    >
      <Spacing mBottom={spacing.defaultSmall}>
        <BodyText color={colors.grey.primary}>{t('filters.date_range.select_start_date')}</BodyText>
      </Spacing>
      <DateField
        id={`${name}-start`}
        key={`${name}-start`}
        label={t('filters.date_range.date')}
        onChange={handleStartDateChange}
        value={startDate}
        max={endDate}
      />
      <Spacing mBottom={spacing.defaultSmall}>
        <BodyText color={colors.grey.primary}>{t('filters.date_range.select_end_date')}</BodyText>
      </Spacing>
      <DateField
        id={`${name}-end`}
        key={`${name}-end`}
        label={t('filters.date_range.date')}
        onChange={handleEndDateChange}
        value={endDate}
        min={startDate}
      />

      <ApplyFilter
        filterLabel={label || ''}
        isDisabled={(!startDate && !endDate) || isSrpLoading}
        handleApplyFilter={handleApplyFilter}
        handleResetFilter={handleResetRange}
        shouldShowResetButton={!!startDate || !!endDate}
        isLoading={loading}
      />
    </AccordionItem>
  )
}
