import { isCarsTrucks } from '@kijiji/category'
import { useDecision } from '@optimizely/react-sdk'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import React, { Fragment } from 'react'

import { ErrorBoundary } from '@/components/shared/error-boundary'
import { ListingCard } from '@/components/srp/listing-card'
import { AutosCrossPromoListingCard } from '@/components/srp/listing-card/autos-x-promo-listing-card/AutosCrossPromoListingCard'
import { AutosListingCard } from '@/components/srp/listing-card/AutosListingCard'
import { type ListingCardPrice } from '@/components/srp/listing-card/ListingCard'
import {
  AUTOS_ATTRIBUTES,
  getAdSourceForPromotionListing,
  getAttributesDictionary,
  getCarsVehiclesAttributes,
  getTransmissionTypeLiteralKey,
  shouldShowPriceDrop,
} from '@/components/srp/search-list/domain'
import { getRealEstateListingAttributes } from '@/components/srp/search-list/getRealEstateListingAttributes'
import { getListingBadgeLabel } from '@/domain/listings/getListingBadgeLabel'
import { getListingProximityLabel } from '@/domain/srp/getListingProximityLabel'
import { isSeeAllTopAdsView } from '@/domain/srp/isSeeAllTopAdsView'
import { SearchListIntersectionObserver } from '@/domain/srp/SearchListIntersectionObserver'
import { trackEventListingClick } from '@/domain/srp/tracking/trackEventListingClick'
import { useGetSearchKeyword } from '@/hooks/keywords/useGetSearchKeywords'
import { useLocale } from '@/hooks/useLocale'
import { InlineAdSlot } from '@/lib/ads/ad-slots/srp/InlineAdSlot'
import { FEATURE_FLAG } from '@/lib/optimizely'
import { type Listing, isAutosListing } from '@/types/search'

type RenderListingCardProps = {
  globalCategoryId: number
  index: number
  isTopAd?: boolean
  item: Listing
  totalListings: number
  isICOActive: boolean
  isCrossPromotionCard?: boolean
  isMobile?: boolean
  searchProvinceId?: number
}

const ValueEstimate = dynamic(
  () => import('@/components/srp/value-estimate').then((mod) => mod.ValueEstimate),
  { ssr: false }
)

export const RenderListingCard = ({
  globalCategoryId,
  index,
  isTopAd,
  item,
  totalListings,
  isCrossPromotionCard,
  isICOActive,
  isMobile,
  searchProvinceId,
}: RenderListingCardProps) => {
  /**
   * It should check the current category based on the listing data not activeCategory cache
   * If the active category is ALL CATEGORIES, none of the listing specific logic would work
   */
  const { price, categoryId: listingCategoryId, flags, attributes: unfilteredAttributes } = item
  const isCarsTrucksSearch = isCarsTrucks(listingCategoryId)

  const { t } = useTranslation('srp')
  const { routeLocale } = useLocale()
  const { query } = useRouter()
  const { keyword } = useGetSearchKeyword()
  const [valueEstimateFlag, isClientReady] = useDecision(FEATURE_FLAG.SRP_ICO)

  // attributes could have null values, so we filter them out
  const attributes = (unfilteredAttributes?.all || []).filter((attr) => !!attr)

  const attributesDictionary = attributes.length ? getAttributesDictionary(attributes) : {}

  const isSeeAllTopAd = isSeeAllTopAdsView(query)

  const showcaseImages = flags.showcase ? item.imageUrls?.slice(1) : []

  const hasShowcaseImages = showcaseImages.length > 0
  const hasFinancingOption = !!('financingAvailable' in flags && flags.financingAvailable)

  const handleTrackListingClick = (photoshowcaseClick?: boolean) => {
    trackEventListingClick({
      listing: item,
      position: index,
      isSeeAllTopAd,
      keywords: keyword,
      photoshowcaseClick,
    })
  }

  const msrp = (price && 'msrp' in price && price.msrp) || undefined
  const amount = (price && 'amount' in price && price.amount) || undefined
  const originalAmount = (price && 'originalAmount' in price && price.originalAmount) || undefined
  const surcharges = (price && 'surcharges' in price && price.surcharges) || undefined
  const hasPriceDropValue = shouldShowPriceDrop(!!flags.priceDrop, originalAmount)

  const cardPrice: ListingCardPrice = {
    amount: amount,
    original: hasPriceDropValue ? originalAmount : undefined,
    type: price?.type,
    surcharges,
  }

  /** If is a conventional listing, then find all the values needed to render it */
  const imageBadge = getListingBadgeLabel({
    categoryId: listingCategoryId,
    categorySpecificBadge: !!flags.categorySpecificBadge,
  })

  /**
   * Distance
   * Should return the proximity value from the user in meters
   */
  const proximity = getListingProximityLabel(item.location?.distance)

  /**
   * Get attributes for specific categories
   * To save processing, the listing will only search for certain attributes if is the right category
   * */
  const { additionalOptions, realEstateAttributes, rentalBadge, virtualTour } =
    getRealEstateListingAttributes(
      attributesDictionary,
      item.location.nearestIntersection,
      listingCategoryId,
      t
    )

  const { carfax, carsVehiclesAttributes, cpo, dealerUpdates, isNew } = getCarsVehiclesAttributes(
    attributesDictionary,
    listingCategoryId,
    routeLocale,
    t
  )

  const key = `listing-${item.id}-${index}`

  const promotionLabelValue = getAdSourceForPromotionListing(
    item.flags,
    item.adSource,
    isSeeAllTopAd
  )

  const autosVipUrl = (isCrossPromotionCard && isAutosListing(item) && item.moveUrl) || item.url

  return (
    <Fragment key={key}>
      <SearchListIntersectionObserver data-testid={`listing-card-list-item-${index}`} index={index}>
        <ErrorBoundary fingerprintId="RenderListingCard">
          {isCrossPromotionCard ? (
            <AutosCrossPromoListingCard
              id={item.id}
              locationName={item.location.name}
              title={item.title}
              url={autosVipUrl}
              attributes={attributesDictionary}
              mainImage={item.imageUrls?.[0]}
              price={cardPrice}
              msrp={(isNew && msrp) || undefined}
              isMobile={isMobile}
              globalKeyword={keyword}
            />
          ) : isCarsTrucksSearch ? (
            <AutosListingCard
              adSource={item.adSource}
              categoryId={listingCategoryId}
              activationDate={item.activationDate}
              sortingDate={item.sortingDate}
              additionalOptions={additionalOptions}
              attributes={{
                dealerUpdates,
                hasCarfaxReport: carfax,
                isCertifiedPreOwned: cpo,
                msrp: isNew ? msrp : undefined,
                km: attributesDictionary[AUTOS_ATTRIBUTES.KILOMETERS]?.[0],
                transmission: attributesDictionary[AUTOS_ATTRIBUTES.TRANSMISSION]?.[0]
                  ? t(
                      getTransmissionTypeLiteralKey(
                        +attributesDictionary[AUTOS_ATTRIBUTES.TRANSMISSION]?.[0]
                      )
                    )
                  : undefined,
              }}
              imageBadge={imageBadge}
              description={item.description}
              id={item.id}
              isHighlight={!!flags.highlight}
              locationName={item.location.name}
              logoSrc={attributesDictionary[AUTOS_ATTRIBUTES.LOGO]?.[0]}
              mainImage={item.imageUrls?.[0]}
              price={cardPrice}
              proximity={proximity}
              showcaseImages={flags.showcase ? item.imageUrls?.slice(1) : []}
              title={item.title}
              url={item.url}
              handleTrackListingClick={handleTrackListingClick}
              promotionLabel={promotionLabelValue}
              isInTopAdsSection={isTopAd || isSeeAllTopAd}
              isMobile={isMobile}
              index={index}
              globalKeyword={keyword}
            />
          ) : (
            <ListingCard
              adSource={item.adSource}
              categoryId={listingCategoryId}
              activationDate={item.activationDate}
              sortingDate={item.sortingDate}
              additionalOptions={additionalOptions}
              autos={{
                dealerUpdates,
                hasCarfaxReport: carfax,
                isCertifiedPreOwned: cpo,
                msrp: isNew ? msrp : undefined,
                hasFinancingOption,
              }}
              imageBadge={imageBadge}
              description={item.description}
              id={item.id}
              inlineAttributes={carsVehiclesAttributes}
              realEstateAttributes={realEstateAttributes}
              isHighlight={!!flags.highlight}
              locationName={item.location.name}
              logoSrc={attributesDictionary[AUTOS_ATTRIBUTES.LOGO]?.[0]}
              mainImage={item.imageUrls?.[0]}
              price={cardPrice}
              proximity={proximity}
              realEstate={{ rentalBadge, virtualTour }}
              isShippedBySeller={!!flags.shippedBySeller}
              showcaseImages={showcaseImages}
              title={item.title}
              url={item.url}
              handleTrackListingClick={() => handleTrackListingClick(hasShowcaseImages)}
              promotionLabel={promotionLabelValue}
              isInTopAdsSection={isTopAd || isSeeAllTopAd}
              isMobile={isMobile}
              index={index}
              globalKeyword={keyword}
              globalCategoryId={globalCategoryId}
            />
          )}
        </ErrorBoundary>
      </SearchListIntersectionObserver>

      {!isTopAd && (
        <>
          <InlineAdSlot index={index} totalListings={totalListings} />
          {isClientReady && valueEstimateFlag.enabled && isCarsTrucksSearch && isICOActive && (
            <ValueEstimate index={index} searchProvinceId={searchProvinceId} />
          )}
        </>
      )}
    </Fragment>
  )
}
