import Image from 'next/image'
import styled from 'styled-components'

import { Modal } from '@/components/modal'
import { zIndexRegistry } from '@/constants/zIndexRegistry'
import { TRANSITION_STYLE } from '@/ui/constants/commonStyles'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

// GUEST MODAL
export const StyledModal = styled(Modal)(
  ({ theme }) => `
    display: flex;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;

    ${MEDIA_QUERIES(theme).medium} {
      height: fit-content;
      left: 50%;
      min-height: 66rem;
      top: 17.9rem;
      transform: translateX(-50%);
      width: 48rem;
    }
  `
)

export const CloseModalButton = styled.button(
  ({ theme }) => `
  & svg {
    color: ${theme.colors.grey.primary};
    height: 3.6rem;
    width: auto;
  }
`
)

export const SaveSearchIcon = styled(Image)(
  ({ theme }) => `
  margin: auto;
  display: none;

  ${MEDIA_QUERIES(theme).medium} {
    display: block;
  }
`
)

export const SaveSearchIconContainer = styled.div`
  height: 20rem;
  margin: 0 auto;
  position: relative;
  text-align: center;
  width: 20rem;
`

export const SaveSearchModalHeader = styled.div(
  ({ theme }) => `
  align-self: flex-end;
  display: flex;
  margin-bottom: ${theme.spacing.defaultSmall};
`
)

export const SaveSearchModalPanel = styled.div(
  ({ theme }) => `
    background: ${theme.colors.white};
    border-radius: ${theme.borderRadius.medium};
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    padding: ${theme.spacing.default} ${theme.spacing.default} ${theme.spacing.husky};
    width: 100%;
  `
)

type SaveSearchFloatingContainerProps = {
  isVisible: boolean
}

export const SaveSearchFloatingContainer = styled.div<SaveSearchFloatingContainerProps>(
  ({ theme, isVisible }) => `
  ${TRANSITION_STYLE}
  bottom: ${theme.spacing.default};
  display: flex;
  z-index: ${zIndexRegistry.FLOATING_CTA};

  ${MEDIA_QUERIES(theme).large}{
    left: 50%;
    position: fixed;
    transform: ${isVisible ? `translate(-50%, 0)` : `translate(-50%, 150%)`};
  }
`
)
