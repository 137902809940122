import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { useEffect, useRef } from 'react'

import { hasSystemMsgQueryParam } from '@/domain/hasSystemMsgQueryParam'
import { getSrpComposedUrl } from '@/domain/srp/getSrpComposedUrl'
import { isUserAuthenticated } from '@/domain/user'
import { useSaveSearchActions } from '@/hooks/useSaveSearchActions'
import { isGaSaveSearchType } from '@/lib/ga/constants/ga'

type SaveSearchFromLoginProps = {
  searchString?: string
}

/**
 * This hook should be called once in the root of a page that takes a save search after login
 *
 * The useSaveSearchActions hook can be called from anywhere in the page,
 * But this code should only be triggered once on the top of it to run the saveSearchBegin flow
 * When a user just came from a login
 */
export const useSaveSearchFromLogin = ({ searchString }: SaveSearchFromLoginProps) => {
  const isFirstRender = useRef<boolean>(true)
  const { query, replace } = useRouter()
  const { status: userSessionStatus } = useSession()
  const { handleActivateSaveSearch } = useSaveSearchActions({ searchString: searchString || '' })

  useEffect(() => {
    /**
     * While we resolve the shallow routing the query parameter of the login system message we will skip one of the re-renders
     *
     * Waiting for the query to be removed from the router and page reloaded to then save the search
     * Or else, it will set trigger the begin event multiple times
     */
    if (
      !isFirstRender.current ||
      !isUserAuthenticated(userSessionStatus) ||
      hasSystemMsgQueryParam(query)
    ) {
      return
    }

    // If saveSearch is already set - trigger save search - means that is coming from sign in
    const { saveSearch, ...rest } = query
    isFirstRender.current = false

    if (saveSearch && isGaSaveSearchType(saveSearch)) {
      handleActivateSaveSearch(saveSearch)

      const redirectQuery = getSrpComposedUrl(rest)
      replace(`${redirectQuery}`)
    }
  }, [handleActivateSaveSearch, replace, userSessionStatus, query])
}
