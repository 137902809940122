import { isCarsVehiclesCategory } from '@kijiji/category'
import { type SearchCategory } from '@kijiji/generated/graphql-types'
import { useDecision } from '@optimizely/react-sdk'
import { useEffect, useMemo } from 'react'

import { type PAGE_TYPE } from '@/constants/pageTypes'
import { getDealerIds } from '@/domain/listings/getDealerIds'
import { type ClarivoyData } from '@/lib/ads/components/clarivoy/clarivoyConfig'
import {
  isClarivoyLoaded,
  onClarivoyLoaded,
  sendClarivoyData,
  transformDealerIdsToClarivoyFormat,
} from '@/lib/ads/components/clarivoy/clarivoyScript'
import { FEATURE_FLAG } from '@/lib/optimizely'
import { type Listing } from '@/types/search'

const useClarivoy = ({
  listings,
  category,
  pageType,
}: {
  listings: Listing[]
  category: SearchCategory
  pageType: PAGE_TYPE
}): ClarivoyData => {
  const [clarivoyFeatureFlag] = useDecision(FEATURE_FLAG.CLARIVOY_SRP)

  // We only want to get the dealers if it's Cars and Vehicles because Clarivoy is an autos marketing tool
  const dealerIds = useMemo(
    () =>
      clarivoyFeatureFlag.enabled && isCarsVehiclesCategory(category.id)
        ? getDealerIds(listings)
        : [],
    [category, clarivoyFeatureFlag.enabled, listings]
  )

  const isClarivoyScriptLoadable =
    clarivoyFeatureFlag.enabled && isCarsVehiclesCategory(category.id) && !!dealerIds.length

  useEffect(() => {
    if (!isClarivoyScriptLoadable) return

    const data = {
      dealerIds: transformDealerIdsToClarivoyFormat(dealerIds),
      pageType,
    }

    if (isClarivoyLoaded()) {
      sendClarivoyData(data)
    } else {
      onClarivoyLoaded(() => {
        sendClarivoyData(data)
      })
    }
  }, [listings, category, isClarivoyScriptLoadable, dealerIds, pageType])

  return {
    isScriptLoadable: isClarivoyScriptLoadable,
  }
}

export default useClarivoy
