import { type IconProps } from '@kijiji/icons/src/components/Icon'
import IconEntryMileage from '@kijiji/icons/src/icons/EntryMileage'
import IconFuel from '@kijiji/icons/src/icons/Fuel'
import IconTransmission from '@kijiji/icons/src/icons/Transmission'
import { formatWholeNumber } from '@kijiji/number/formatWholeNumber'
import { useTranslation } from 'next-i18next'
import { useTheme } from 'styled-components'

import { AutosAttributeListItem } from '@/components/srp/listing-card/autos-attribute-list/AutosAttributeListItem'
import { AutosAttributeListFlex } from '@/components/srp/listing-card/autos-attribute-list/styled'
import { type AutosListingAttributes } from '@/components/srp/listing-card/AutosListingCard'
import { FUEL_TYPES } from '@/components/srp/search-list/domain'
import { ROUTE_LOCALE, useLocale } from '@/hooks/useLocale'
import { capitalizeString } from '@/utils/string'

type AutosAttributeListProps = {
  attributes: AutosListingAttributes
}

// TODO: We can migrate this to use the List component from @kijiji/ui once it's able to support all the small tweaks we need.
export const AutosAttributeList = ({ attributes }: AutosAttributeListProps) => {
  const { t } = useTranslation('srp')
  const { km, transmission, fuelType } = attributes
  const theme = useTheme()
  const { routeLocale } = useLocale()

  const defaultIconProps: IconProps = {
    color: theme.colors.grey.primary,
    size: 'xxsm',
  }

  const formattedKilometers = km
    ? formatWholeNumber(Number(km), routeLocale === ROUTE_LOCALE.en)
    : undefined

  const isFuelTypeVisible = fuelType && fuelType !== FUEL_TYPES.OTHER

  return (
    <AutosAttributeListFlex flexDirection="row" flexWrap="wrap" columnGap={theme.spacing.default}>
      {km ? (
        <AutosAttributeListItem
          data-testid="autos-attribute-kilometers"
          text={t('listing.attributes.kilometers.value', { kms: formattedKilometers })}
          icon={<IconEntryMileage {...defaultIconProps} aria-hidden />}
        />
      ) : null}
      {transmission ? (
        <AutosAttributeListItem
          data-testid="autos-attribute-transmission"
          text={transmission}
          icon={<IconTransmission {...defaultIconProps} aria-hidden />}
        />
      ) : null}
      {isFuelTypeVisible ? (
        <AutosAttributeListItem
          data-testid="autos-attribute-fuel-type"
          text={capitalizeString(fuelType)}
          icon={<IconFuel {...defaultIconProps} aria-hidden />}
        />
      ) : null}
    </AutosAttributeListFlex>
  )
}
