import { type AttributeValue } from '@kijiji/generated/graphql-types'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'

import { TreeFilter } from '@/components/srp/filters/filter-types/TreeFilter'
import { type TreeFilterProps } from '@/components/srp/filters/FiltersAccordion/FiltersAccordion'
import { getDescriptionFromSelectedValues } from '@/domain/srp/filters/getDescriptionFromSelectedValues'
import { getCategoryLevel } from '@/domain/srp/getCategoryLevel'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { AccordionItem } from '@/ui/atoms/accordion'

export const CategoryTreeFilter: FC<TreeFilterProps> = ({
  filter: categoryTree,
  selectedId: categoryId,
  refetch: refetchResults,
}) => {
  const { t } = useTranslation('srp')
  const description = getDescriptionFromSelectedValues(
    categoryTree?.selectedValues,
    categoryTree?.values
  )

  const handleSelectCategory = (categoryLeaf: AttributeValue) => {
    const selectedCategoryId = parseInt(categoryLeaf.value)

    if (selectedCategoryId === categoryId) return

    const categoryLevel = getCategoryLevel({ categoryId: selectedCategoryId })
    const trackingLabel = `cat${categoryLevel}=${selectedCategoryId}`

    refetchResults(
      { categoryId: selectedCategoryId, offset: 0 },
      {
        event: {
          action: GA_EVENT.CategorySelect,
          label: trackingLabel,
        },
        forceNavigateUrl: categoryLeaf.seoUrl || undefined,
      }
    )
  }

  const handleAccordionClick = () => {
    trackEvent({ action: GA_EVENT.CategorySelectBegin })
  }

  if (!categoryTree) return null

  return (
    <AccordionItem
      id="categorySection"
      onClick={handleAccordionClick}
      title={t('filters.category.title')}
      description={description}
    >
      <TreeFilter
        attributes={categoryTree}
        handleSelectAttribute={handleSelectCategory}
        selectedId={categoryId}
        shouldSelectLeaf
        type="category"
      />
    </AccordionItem>
  )
}
