import { AdvertisingSlot } from 'react-advertising'

import { StyledTopSlotWrapper } from '@/lib/ads/ad-slots/srp/styled'

type TopSlotWrapperProps = {
  id: string
}

export const TopSlotWrapper = ({ id, ...props }: TopSlotWrapperProps) => {
  return (
    <StyledTopSlotWrapper>
      <AdvertisingSlot id={id} {...props} />
    </StyledTopSlotWrapper>
  )
}
