import { type AttributeValue } from '@kijiji/generated/graphql-types'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { SearchLocation } from '@/components/shared/global-header/search-bar/SearchLocation'
import { AddressWrapper } from '@/components/srp/filters/filter-types/styled'
import { TreeFilter } from '@/components/srp/filters/filter-types/TreeFilter'
import { type TreeFilterProps } from '@/components/srp/filters/FiltersAccordion/FiltersAccordion'
import { getUserLocationLabel } from '@/domain/location/getUserLocationLabel'
import { useGetLocation } from '@/hooks/location/useGetLocation'
import { useLocale } from '@/hooks/useLocale'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { AccordionItem } from '@/ui/atoms/accordion'
import { Flex } from '@/ui/atoms/flex'
import { Spacing } from '@/ui/atoms/spacing'

export const LocationTreeFilter: FC<TreeFilterProps> = ({
  filter: locationTree,
  refetch: refetchResults,
}) => {
  const { location, updateUserLocationById } = useGetLocation()

  const { spacing } = useTheme()
  const { t } = useTranslation('srp')
  const { apiLocale } = useLocale()

  const handleSelectLocation = (locationLeaf: AttributeValue) => {
    const selectedLocationId = parseInt(locationLeaf.value)

    if (selectedLocationId === location.id) return

    const trackingLabel = `loc=${selectedLocationId}`

    refetchResults(
      { location: { id: selectedLocationId }, offset: 0 },
      {
        event: {
          action: GA_EVENT.LocationSelect,
          label: trackingLabel,
        },
        forceNavigateUrl: locationLeaf.seoUrl || undefined,
      }
    )

    updateUserLocationById(selectedLocationId)
  }

  const handleAccordionItemClick = () => {
    trackEvent({ action: GA_EVENT.LocationSelectBegin })
  }

  const description = (
    <Flex alignItems="center">
      <AddressWrapper>{getUserLocationLabel(apiLocale, location)}</AddressWrapper>
      {!location.isRegion && ` • ${location?.area?.radius}km`}
    </Flex>
  )

  if (!locationTree) return

  return (
    <AccordionItem
      id="locationSection"
      onClick={handleAccordionItemClick}
      title={t('filters.location.title')}
      description={description}
    >
      <SearchLocation id="set-search-range-filter" />

      <Spacing mTop={spacing.default}>
        <TreeFilter
          attributes={locationTree}
          handleSelectAttribute={handleSelectLocation}
          selectedId={location.id}
          shouldSelectLeaf={location.isRegion}
          type="location"
        />
      </Spacing>
    </AccordionItem>
  )
}
