import Script from 'next/script'
import { useTranslation } from 'next-i18next'
import { useCallback, useEffect, useState } from 'react'
import { useTheme } from 'styled-components'

import { HideAtOrLarger, ShowAtOrLarger } from '@/components/shared/breakpoint'
import { ListingCardContainer } from '@/components/srp/listing-card/styled'
import {
  ValueEstimateButton,
  ValueEstimateDescription,
  ValueEstimateHeading,
  ValueEstimateImage,
} from '@/components/srp/value-estimate/ValueEstimate.styled'
import { AC_URL, AUTOCAN_CTA_IDS, ICO_UTM_PARAMS } from '@/constants/ico'
import { PROVINCES_ABBREVIATION } from '@/constants/location'
import { getCurrentPageFromPagination } from '@/domain/srp/getCurrentPageFromPaginationData'
import { useGetSearchResultsData } from '@/hooks/srp/useGetSearchResultsData'
import { useLocale } from '@/hooks/useLocale'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { Flex } from '@/ui/atoms/flex'
import { getIcoScriptURL, openValueEstimateModal } from '@/utils/autoCanadaHelper'
import { sendToLogger } from '@/utils/sendToLogger'

const DEFAULT_VALUE_ESTIMATE_POSITION = 3

export type ValueEstimateProps = {
  index: number
  searchProvinceId?: number
}

export const ValueEstimate = ({ index, searchProvinceId }: ValueEstimateProps) => {
  const { t } = useTranslation('srp')
  const { spacing, colors } = useTheme()
  const { routeLocale } = useLocale()
  const { data } = useGetSearchResultsData()
  const [valueEstimatePosition, setValueEstimatePosition] = useState(
    DEFAULT_VALUE_ESTIMATE_POSITION
  )

  const { totalCount = 0 } = data?.pagination || {}
  const currentPage = getCurrentPageFromPagination(data?.pagination || {})

  const provinceAbbreviation = searchProvinceId
    ? PROVINCES_ABBREVIATION[searchProvinceId]
    : undefined

  useEffect(() => {
    if (totalCount === 0) {
      // Don't show the component if there are no listings.
      setValueEstimatePosition(-1)
    } else if (totalCount <= DEFAULT_VALUE_ESTIMATE_POSITION) {
      // Show the component at the end if there are too few listings.
      setValueEstimatePosition(totalCount - 1)
    }
  }, [totalCount])

  const handleClick = useCallback(() => {
    trackEvent({ action: GA_EVENT.ValueEstimateBegin })
    openValueEstimateModal({
      routeLocale,
      icoDataProps: {
        ctaId: AUTOCAN_CTA_IDS.VALUE_ESTIMATE_SRP,
        province: provinceAbbreviation,
      },
      utmParams: {
        utmCampaign: ICO_UTM_PARAMS.UTM_CAMPAIGN_ICO,
        utmContent: ICO_UTM_PARAMS.UTM_CONTENT_ESTIMATE_SRP,
        utmSource: ICO_UTM_PARAMS.UTM_SOURCE,
      },
    })
  }, [routeLocale, provinceAbbreviation])

  // Only show the component if it's at the right position in the first page.
  if (index !== valueEstimatePosition || currentPage !== 1) {
    return null
  }

  return (
    <>
      <Script
        id={AC_URL.ICO_SCRIPT_ID}
        onError={(err) => sendToLogger(err, { fingerprint: ['ValueEstimate'] })}
        src={getIcoScriptURL()}
        strategy="lazyOnload"
      />

      <ListingCardContainer>
        <Flex
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          gap={spacing.default}
        >
          <ValueEstimateImage alt="" src="/next-assets/images/autocanada/ico-srp-cta.png" />
          <Flex flexDirection="column" alignItems="flex-start" gap={spacing.default}>
            <Flex flexDirection="column" alignItems="flex-start" gap={spacing.mini}>
              <ValueEstimateHeading weight="medium">
                {t('value_estimate.heading')}
              </ValueEstimateHeading>
              <ShowAtOrLarger breakpoint="medium">
                <ValueEstimateDescription color={colors.grey.light1}>
                  {t('value_estimate.desktop_description')}
                </ValueEstimateDescription>
              </ShowAtOrLarger>
              <HideAtOrLarger breakpoint="medium">
                <ValueEstimateDescription color={colors.grey.light1}>
                  {t('value_estimate.mobile_description')}
                </ValueEstimateDescription>
              </HideAtOrLarger>
            </Flex>
            <ValueEstimateButton onClick={handleClick}>
              <ShowAtOrLarger breakpoint="medium">
                {t('value_estimate.desktop_button_text')}
              </ShowAtOrLarger>
              <HideAtOrLarger breakpoint="medium">
                {t('value_estimate.mobile_button_text')}
              </HideAtOrLarger>
            </ValueEstimateButton>
          </Flex>
        </Flex>
      </ListingCardContainer>
    </>
  )
}
